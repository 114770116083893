@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body{
    margin:0px;
    padding: 0px;
    overflow-x: hidden  ;
    background-color: #ffffff;
    font-family: 'Roboto', sans-serif !important;
    
}
*{
    box-sizing: border-box;
}
ul{
    list-style: none;
}
a{
    text-decoration: none;
}
button{
    outline: none;
    border: none;
}
input{
    outline: none;
    border: none;
}
.logo img{
    width: 90px;
}
#main{
   
    width:100%;
    height:768px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('./images/background.png');
    /* background-image: url('./images/Illustration\ 1.png'); */
}
nav{
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	width:100%;
	z-index: 1;
    background-color: transparent;
    transition: 0.6s ease-in-out;
}
nav.active{
	box-shadow: 5px 10px 30px rgba(0, 247, 255, 0.157);
	background-color: #ffffff;
}
nav ul{
	display: flex;
}
.active{
    background-color: #00b7ff;
    color:#ffffff;
	box-shadow: 5px 10px 30px rgba(189, 198, 64, 0.411);
	transition: all ease 0.2s;
}
nav ul li a{
    font-family: calibri;
	height:40px;
	line-height: 43px;
	margin: 3px;
	padding: 0px 22px;
	display: flex;
	font-size: 0.9rem;
	text-transform: uppercase;
	font-weight: 500;
	color:#000000;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;
}
nav ul li a:hover{
	background-color: #00b7ff;
    color:#ffffff;
	box-shadow: 5px 10px 30px rgba(2, 238, 255, 0.336);
	transition: all ease 0.2s;
}
nav .menu-btn,
.menu-icon{
		display:none;
	}
.name{
	font-family: 'Montserrat';
	width: 500px;
	position: absolute;
    left: 22%;
	top: 50%;
	transform: translate(-22%,-50%);
}
.name span{
    color: #00b7ff;
}
.name .details{
    color: #969696;
}
.name h1{
	font-family:'Montserrat';
	font-size: 40px;
	margin:0px;
	letter-spacing: 2px;
	color:#000000;
}
.header-btns{
    display: flex;
    margin-top: 40px;
}
.cv-btn{
	width:110px;
	height: 40px;
	display: flex;
	justify-content: center;
    align-items: center;
    background-color: red;
    box-shadow: 5px 10px 30px rgba(2, 238, 255, 0.336);
    border-radius: 10px;
	color:#ffffff;
}
.cv-btn:hover{
	background-color: #0099ff;
    transition: all ease 0.5s;
    color: #ffffff;
}






@media(max-width:1190px){
	#main{
		background-size: 1150px !important;
		
	}
	.name{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
	}
	
    
    
    
    

}

@media(max-width:970px){
	.main{
		background-image: none !important;
		
	}
	.name{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
	
    .cv-btn{
        margin-left: 27%;
        margin-top: 20px;
    }
	
}
@media(max-width:600px){
	.name{
		width:60%;
    }}
    
@media(max-width:1100px){.menu-icon{
    display:block;}
        nav{
            justify-content: space-between;
            height: 65px;
            padding: 0px 30px;
        }
        .logo img{
            width:70px;
        }
        .header-btns{
            margin: 0;
        }
        .menu{
            display:none;
            position:absolute;
            top:65px;
            left:0px;
            background-color:#FFFFFF;
            border-bottom:4px solid #1db096;
            width:100%;
            padding:0px;
            margin:0px;
        }
        .menu li{
            width:100%;
        }
        nav .menu li a{
            width:100%;
            height:40px;
            justify-content: center;
            align-items: center;
            margin:0px;
            padding: 25px;
            border:1px solid rgba(38,38,38,0.03);
        }
        nav .menu-icon{
            cursor:pointer;
            float:right;
            padding:28px 20px;
            position:relative;
            user-select:none;
        }
        nav .menu-icon .nav-icon{
            background-color:#333333;
            display:block;
            height:2px;
            position:relative;
            transition: background 0.2s ease-out;
            width:18px;
        }
        nav .menu-icon .nav-icon:before,
        nav .menu-icon .nav-icon:after{
            background:#333;
            content:'';
            display:block;
            height:100%;
            position:absolute;
            transition:all ease-out 0.2s;
            width:100%;
        }
        nav .menu-icon .nav-icon:before{
            top:5px;
        }
        nav .menu-icon .nav-icon:after{
            top:-5px;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon{
            background:transparent;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
            transform: rotate(-45deg);
            top:0;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
            transform: rotate(45deg);
            top:0;
        }
        nav .menu-btn{
            display:none;
        }
        nav .menu-btn:checked ~ .menu{
            display:block;
        }}
        @media(max-width:400px){
            .s-box{
                width: 100%;
                height:400px;
            }
        }

.ftures{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}        
.provided-features{
    width: 400px;
    height: 400px;
    padding: 25px;
    /* text-align: center; */
    line-height: 30px;
    margin-top: 120px;
}

.heading-plan{
    text-align: center;
    margin-top: 150px;
    /* width: 555px; */
    
}

/* basic planning css */
.freeplan{
position: relative;
width: 330px;
height: 760px;
left: 175px;
top: 100px;

background: #FFFFFF;
border: 2px solid #DDDDDD;
border-radius: 10px;
}
.columns{
    display: flex;
    flex-wrap: wrap;
    gap: 70px;
    text-align: center;
}
.select-btn{
    width:110px;
	height: 40px;
    background-color: red;
    border-radius: 10px;
	color:#ffffff;
}

.map-img{
position: relative;
width: 1060px;
height: 537.77px;
left: 190px;
top: 150px;
opacity: 0.5;
}
.user-rating{
    /* display: flex; */
position: relative;
width: 400px;
height: 230px;
left: 150px;
top: 40px;
background: #FFFFFF;
border: 2px solid #F53838;
border-radius: 10px;
}
.userimg{
    display:flex ;
    padding: 15px;
    /* justify-content: space-evenly; */
}
.testimonials{
display: flex;
flex-wrap: wrap;
gap: 15px;
}
@media only screen and (max-width: 600px){
    .login-system{
        display: none;
    }

    .map-img {
        position: relative;
        width: 337px;
        height: 420.77px;
        left: 20px;
        top: 0px;
        opacity: 0.5;
    }
    .freeplan {
        position: relative;
        width: 330px;
        height: 760px;
        left: 25px;
        top: 100px;
        background: #FFFFFF;
        border: 2px solid #DDDDDD;
        border-radius: 10px;
    }
    .Illustration2{
        width: 250px;
    }
    .user-rating {
        /* display: flex; */
        position: relative;
        width: 385px;
        height: 230px;
        left: 1px;
        top: 40px;
        background: #FFFFFF;
        border: 2px solid #F53838;
        border-radius: 10px;
    }
    
    .listing{
        display: none;
    }
    .main-footer {
        background: #F8F8F8;
        width: 400px !important;
        padding: 50px !important;
        position: relative;
        top: 73px !important;
        left: 0px !important;
        
    }
}
.main-footer{
    background: #F8F8F8;
    width: 1200px;
    padding: 10px;
    position: relative;
    top: 150px;
    display: flex;
    gap: 200px;
    left: 200px;
    /* left: 100px; */
}
.listing li{
    line-height: 30px;
}